import {Component, OnInit} from '@angular/core';
import {Global} from '../global';
import {ValidateSpanishID} from './validaDNI';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';


declare var $;

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.scss']
})
export class AperturaComponent implements OnInit {

  paso = 1;
  apertura = false;
  formulario;
  poblaciones = [];
  cargando = false;
  subiendo_albaran = false;
  n_files = {};
  documentos = [];
  subiendo = {};
  files = [];
  allDocumentos = [];
  tipologias = [];
  causas = [];
  grm_id;
  pol_id;
  pol_oficode;
  confirmar;
  numpoliza;
  showCaptcha;
  errorShowcaptcha;
  newPerjudicado;
  nuevoPerjudicado;
  editPerjudicado;
  imgURL;
  parametrizacion = null;
  cargado = false;

  entidad;
  logo;
  backgroundColor;
  backgroundColorHover;
  showInit = false;
  currentYear;

  sin_id;
  sin_alias;

  rie_id = null;
  showModalMultiplesRiesgos = false;
  bienesAfectados = null;

  siniestro_creado = false;
  errorSubidaDocumento = false;

  bienSeleccionado;

  constructor(public global: Global, private activatedRoute: ActivatedRoute, private router: Router, private location: Location) {
    this.currentYear = new Date().getUTCFullYear();
  }

  async ngOnInit() {
    this.sin_id=null;
    this.sin_alias=null;
    this.siniestro_creado=false;
    this.cargando = false;
    this.parametrizacion = null;
    this.cargando = true;
    this.doAsyncTask();
    //const result = await this.global.apiCall('siniestrosRC/parametrice', 'POST', false, {url: this.activatedRoute.params.value.url});

    this.cargando = false;

    this.cargado = true;
    this.getDocuments();
    // this.init_formulario();
    this.aperturar(0);
  }

  resolved(captchaResponse: string) {
    sessionStorage.setItem('token-empresas-siniestros', captchaResponse);
    this.errorShowcaptcha = null;
  }

  async getDocuments() {
    this.cargando = true;
    const result = await this.global.apiCall('siniestrosRC/getDocuments', 'POST', false, {});
    this.cargando = false;
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      }
      return;
    }

    this.allDocumentos = result.data;

  }

  async getTipologiaSiniestros() {
    this.cargando = true;
    const result = await this.global.apiCall('siniestrosRC/tipologiaSiniestros', 'POST', false, {grm_id: this.grm_id});
    this.cargando = false;
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      }
      return;
    }

    this.tipologias = result.data;
    this.tipologias.push({
      tps_id: -1,
      tps_codigo: -1,
      tps_descripcion: 'No estoy seguro'
    });

  }

  async getCausas() {

    this.causas = [];
    this.formulario.causaSiniestro.value = '';

    if (this.formulario.tipologiaSiniestro.value == null) {
      return;
    }
    const tipologia = this.tipologias.find(t => t.tps_id == this.formulario.tipologiaSiniestro.value);
    this.cargando = true;
    const result = await this.global.apiCall('siniestrosAutos/causaSiniestros', 'POST', false, {tps_codigo: tipologia.tps_codigo});
    this.cargando = false;
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      } else {
        this.causas.push({
          csn_descripcion: 'No estoy seguro',
          csn_id: -1
        });
        this.formulario.causaSiniestro.value = this.causas[0].csn_id;
      }
      return;
    }

    this.causas = result.data;
    if (this.causas.length == 1) {
      this.formulario.causaSiniestro.value = this.causas[0].csn_id;
    } else {
      this.causas.push({
        csn_descripcion: 'No estoy seguro',
        csn_id: -1
      });
    }
  }

  init_formulario() {

    this.formulario = {
      poliza: {
        value: '',
        error: null,
        paso: 1
      },
      nif: {
        value: '',
        error: null,
        paso: 1
      },
      tipoSiniestro: {
        value: 'Daños',
        error: null,
        paso: 1
      },
      tipologiaSiniestro: {
        value: null,
        error: null,
        paso: 2
      },
      causaSiniestro: {
        value: null,
        error: null,
        paso: 2
      },
      fecha: {
        value: '',
        error: null,
        paso: 2
      },
      dirPerjudicado: {
        value: '',
        error: null,
        paso: 2
      },
      cp: {
        value: '',
        error: null,
        paso: 2
      },
      poblacion: {
        value: '',
        error: null,
        paso: 2
      },
      circunstancias: {
        value: '',
        error: null,
        paso: 2
      },
      danos: {
        value: '',
        error: null,
        paso: 2
      },
      nombre: {
        value: '',
        error: null,
        paso: 3,
      },
      telefono: {
        value: '',
        error: null,
        paso: 3
      },
      email: {
        value: '',
        error: null,
        paso: 3
      },
      perjudicados: {
        value: [],
        error: null,
        paso: 4
      },
      sin_id: {
        value: '',
        error: null,
        paso: 'sin_id'
      },
      valorPrevision: {
        value: '',
        error: null,
        paso: 2
      },
    };

    this.paso = 1;
    this.poblaciones = [];
    this.files = [];
    this.documentos = [];
    this.confirmar = false;
    this.numpoliza = '';
    this.newPerjudicado = false;
    this.editPerjudicado = null;

    this.iniTable(null);
    setTimeout(() => {
      $('#fecha').datepicker({
        language: 'es',
        dateFormat: 'dd/mm/yy',
        maxDate: 0,
        firstDay: 1,
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],

      }).change(() => {
        this.formulario.fecha.error = null;
      });
      $('#select2Documentos').val(this.documentos);
      $('#select2Documentos').select2({
        dropdownParent: $('#panel'),
        placeholder: 'Selecciona los documentos a añadir',
      }).on('select2:select', (e) => {
        const doc = this.allDocumentos.find(t => t.doc_id == e.params.data.id);
        this.formulario[doc.doc_descripcion] = {
          value: [],
          error: null,
          id: doc.doc_id,
          paso: 'documentos'
        };
        this.subiendo[doc.doc_id] = false;
        this.n_files[doc.doc_id] = 1;
        this.documentos.push(doc);
        if (this.documentos.length == 1) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      }).on('select2:unselect', (e) => {
        const doc = this.documentos.find(t => t.doc_id == e.params.data.id);
        this.documentos.splice(this.documentos.indexOf(doc), 1);
        if (this.documentos.length == 0) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      });
    }, 100);

    if (sessionStorage.getItem('token-empresas-siniestros')) {
      this.showCaptcha = false;
      if (localStorage.getItem('poliza')) {
        this.formulario.poliza.value = localStorage.getItem('poliza');
      }
      if (localStorage.getItem('nif')) {
        this.formulario.nif.value = localStorage.getItem('nif');
      }
    } else {
      this.showCaptcha = true;
    }

  }

  iniTable(id) {
    setTimeout(() => {
      if (id == 0 || id == null) {
        $('#docsTable').DataTable({
          lengthMenu: [150],
          language: {
            url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          },
        });
      }
      if (id == 1 || id == null) {
        $('#perjudicadosTable').DataTable({
          lengthMenu: [150],
          language: {
            url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          },
        });
      }
    }, 100);
  }


  existsFiles(name) {
    if (this.files.filter(f => f.document == name).length == 0) {
      return false;
    }
    return true;
  }

  async subir_documentos(fileInput: any, documentName, docId) {

    this.subiendo[docId] = true;

    const files = fileInput.target.files as Array<File>;

    for (const file of files) {

      this.files.push({
        file,
        name: file.name,
        document: documentName,
        nombre: documentName + '_' + this.n_files[docId].toString(),
        id: docId
      });

    }

    this.n_files[docId]++;

    $('input[type="file"]').val('');

    this.subiendo[docId] = false;

  }


  async comprobar_campos_paso2(evt = null) {

    if (evt) {
      return false;
    }

    let r = true;

    if (this.paso == 2) {

      this.formulario.fecha.value = $('#fecha').val();


      for (const campo in this.formulario) {
        console.log(campo);
        if (campo == 'poblacion') {
          continue;
        }


        if (this.formulario[campo].paso == 2) {

          this.formulario[campo].error = null;


          if (this.formulario[campo].value == '' || this.formulario[campo].value == null) {
            this.formulario[campo].error = 'Rellena el campo';
            r = false;
          }
          if(campo =='valorPrevision'){
            console.log('evaluando valorPrevision');
            r = this.checkValorPrevision(this.formulario.valorPrevision.value);
            console.log(this.formulario.valorPrevision.value);
          }

        }

      }

      const fecha = new Date(this.convertDataYmd(this.formulario.fecha.value));
      const hoy = new Date();
      const dias = Math.floor((fecha.getTime() - hoy.getTime()) / (1000 * 60 * 60 * 24));

      if (dias >= 0) {
        this.formulario.fecha.error = 'La fecha no puede ser posterior a hoy';
        r = false;
      }

      const c = await this.checkCP(this.formulario.cp.value);

      if (!c) {
        r = false;
      }

      if (this.formulario.poblacion.value == '' && this.poblaciones.length != 0) {
        this.formulario.poblacion.value = this.poblaciones[0];
      }
    }

    return r;

  }

  async comprobar_campos_paso3(evt = null) {

    if (evt) {
      return false;
    }

    let r = true;

    if (this.paso == 3) {

      this.formulario.fecha.value = $('#fecha').val();

      for (const campo in this.formulario) {

        if (this.formulario[campo].paso == 3) {

          this.formulario[campo].error = null;

          if (this.formulario[campo].value == '') {
            this.formulario[campo].error = 'Rellena el campo';
            r = false;
          }

          if (!(/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/).test(this.formulario.telefono.value)) {

            this.formulario.telefono.error = 'Introduzca un número de teléfono válido';
            r = false;

          }

          if (this.formulario.email.value != '') {
            if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.formulario.email.value))) {

              this.formulario.email.error = 'Introduzca un email válido';
              r = false;

            }
          }

        }

      }

    }

    return r;

  }

  async comprobarCamposPerjudicado(evt = null) {
    if (evt) {
      return false;
    }

    let r = true;

    if (this.nuevoPerjudicado) {

      for (const campo in this.nuevoPerjudicado) {

        this.nuevoPerjudicado[campo].error = null;

        if (this.nuevoPerjudicado.tipoPerjudicado.value == 'Persona física' && campo == 'empresa') {
          continue;
        }

        if (this.nuevoPerjudicado[campo].value == '') {

          if (campo == 'apellido2' || campo == 'email' || campo == 'telefono') {
            continue;
          }


          this.nuevoPerjudicado[campo].error = 'Rellena el campo';
          r = false;
        }
        if (this.nuevoPerjudicado.telefono.value != '') {
          if (!(/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/).test(this.nuevoPerjudicado.telefono.value)) {

            this.nuevoPerjudicado.telefono.error = 'Introduzca un número de teléfono válido';
            r = false;

          }
        }

        if (this.nuevoPerjudicado.email.value != '') {
          if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.nuevoPerjudicado.email.value))) {

            this.nuevoPerjudicado.email.error = 'Introduzca un email válido';
            r = false;

          }
        }
      }
    }

    return r;

  }

  async comprobar_campos_paso1(evt = null) {

    if (evt) {
      return false;
    }

    let r = true;

    if (this.paso == 1) {

      for (const campo in this.formulario) {
        if (this.formulario[campo].paso == 1) {

          this.formulario[campo].error = null;

          if (this.formulario[campo].value == '') {
            this.formulario[campo].error = 'Rellena el campo';
            r = false;
          }
        }
      }
      console.log('rie_id is ', this.rie_id);
      if (r != false) {
        this.cargando = true;
        const result = await this.global.apiCall('siniestrosRC/checkDNIPoliza', 'POST', false, {
          nif: this.formulario.nif.value,
          poliza: this.formulario.poliza.value,
        });

        if (result.status == false) {
          if (result.status2 == false) {
            this.formulario.poliza.error = result.message;
          } else {
            this.formulario.nif.error = result.message;
            this.formulario.poliza.error = result.message;
          }
          this.cargando=false;
          r = false;
          return r;
        }
        if (result.status2) {
          this.numpoliza = result.data.Poliza;
          this.confirmar = true;
          r = false;
        } else {
          // if (this.rie_id == null) {
            const datosPoliza = await this.global.apiCall(
              'siniestrosRC/getDatosPoliza',
              'POST',
              false,
              {
                poliza: this.formulario.poliza.value,
              });
            console.log('datospoliza,', datosPoliza);
            if (datosPoliza.counter > 1) {
              this.showModalMultiplesRiesgos = true;
              this.bienesAfectados = datosPoliza.data;
              r = false;
            }
          // }
        }

        this.pol_id = result.data[0].POL_Id;
        this.pol_oficode = result.data[0].pol_oficode;
        console.log(this.pol_oficode);
        this.grm_id = result.data[0].grm_id;
        this.rie_id = result.data[0].rie_id;

        await this.getTipologiaSiniestros();
      }
    }

    this.cargando = false;
    return r;

  }

  async confirmaDatos(value) {
    if (value == 1) {
      this.formulario.poliza.value = this.numpoliza;
      this.confirmar = false;
      this.paso2();
    } else {
      this.numpoliza = '';
      this.confirmar = false;
      return;
    }
  }

  async paso2() {

    if (!sessionStorage.getItem('token-empresas-siniestros')) {
      this.errorShowcaptcha = 'Completa el campo';
      return;
    }

    const c = await this.comprobar_campos_paso1();

    if (!c) {
      return;
    }

    if (this.formulario.poliza.value != '') {
      localStorage.setItem('poliza', this.formulario.poliza.value);
    }
    if (this.formulario.nif.value != '') {
      localStorage.setItem('nif', this.formulario.nif.value);
    }

    this.paso = 2;


  }

  async paso3() {

    const c = await this.comprobar_campos_paso2();

    if (!c) {
      return;
    }

    this.paso = 3;

  }

  async paso4() {

    const c = await this.comprobar_campos_paso3();

    if (!c) {
      return;
    }

    this.paso = 4;
  }

  async paso5() {
    if (this.formulario.perjudicados.value.length == 0) {
      this.formulario.perjudicados.error = 'Debe incluir al menos un perjudicado';
      return;
    }

    this.paso = 5;

  }

  clickInput(input) {
    document.getElementById('input_' + input).click();
  }

  abrir_documento(file) {

    window.open(file.url);

  }

  aperturar(num) {
    this.sin_id=null;
    this.sin_alias=null;
    this.siniestro_creado=false;
    if (num == 1) {
      if (localStorage.getItem('poliza')) {
        localStorage.removeItem('poliza');
      }
      if (localStorage.getItem('nif')) {
        localStorage.removeItem('nif');
      }
    }
    this.init_formulario();
    this.apertura = true;
    if (this.parametrizacion) {
      this.formulario.nif.value = this.parametrizacion.NIF;
      this.formulario.poliza.value = this.parametrizacion.Poliza;
      setTimeout(function() {
        $('#formPoliza').prop('disabled', true);
        $('#formNif').prop('disabled', true);
      }, 50);
    }
  }

  cancelarApertura() {
    this.apertura = false;
  }

  async checkCP(cp) {

    this.poblaciones = [];

    this.formulario.cp.error = null;

    if (this.formulario.cp.value == '') {

      this.formulario.cp.error = 'Rellena el campo';
      return false;

    } else if (isNaN(parseFloat(this.formulario.cp.value))) {

      this.formulario.cp.error = 'Introduzca un código postal válido';
      return false;

    } else if (this.formulario.cp.value.length != 5) {

      this.formulario.cp.error = 'Introduzca un código postal válido';
      return false;

    }

    this.cargando = true;
    const result = await this.global.apiCall('siniestrosRC/checkCP', 'POST', false, {cp});
    this.cargando = false;

    if (!result.status) {

      this.formulario.cp.error = result.message;
      return false;

    }

    this.poblaciones = result.data;

    return true;

  }

  uploadFile(file, name) {

    const size = file.size / 1024 / 1014;

    if (size >= 20) {
      return {
        status: false,
        message: 'No se pueden subir archivos con un tamaño superior a 20Mb'
      };
    }

    const formData = new FormData();

    formData.append('select_file', file, file.name);
    formData.append('file_name', name);
    formData.append('sin_id', this.formulario.sin_id.value);

    return $.ajax({
      url: this.global.config.api + 'siniestrosRC/uploadFile',
      method: 'POST',
      data: formData,
      dataType: 'JSON',
      contentType: false,
      cache: false,
      processData: false
    });

  }

  async delete_file(key, i) {

    this.files.splice(i, 1);

  }

  async uploadDocumentos() {

    for (const file of this.files) {
      this.cargando = true;

      const result = await this.uploadFile(file.file, file.nombre);

      this.cargando = false;

      if (result.message) {
        alert('Se ha producido un error en la subida de documentos');
        return;
      }

      const url = result.url;
      const new_name = result.new_name;

      const type = file.file.type.split('/')[0];

      this.formulario[file.document].value.push({
        url,
        type,
        name: file.name,
        new_name
      });
    }
  }

  newPerj() {
    this.newPerjudicado = true;
    this.nuevoPerjudicado = {
      nombre: {
        value: '',
        error: null,
      },
      tipoPerjudicado: {
        value: 'Persona física',
        error: null,
      },
      empresa: {
        value: '',
        error: null,
      },
      apellido1: {
        value: '',
        error: null,
      },
      apellido2: {
        value: '',
        error: null,
      },
      telefono: {
        value: '',
        error: null,
      },
      email: {
        value: '',
        error: null,
      },
    };
  }

  editPerj(i, perjudicado) {
    this.editPerjudicado = i;
    this.newPerjudicado = true;
    this.nuevoPerjudicado = {
      nombre: {
        value: perjudicado.nombre.value,
        error: null,
      },
      tipoPerjudicado: {
        value: perjudicado.tipoPerjudicado.value,
        error: null,
      },
      empresa: {
        value: perjudicado.empresa.value,
        error: null,
      },
      apellido1: {
        value: perjudicado.apellido1.value,
        error: null,
      },
      apellido2: {
        value: perjudicado.apellido2.value,
        error: null,
      },
      telefono: {
        value: perjudicado.telefono.value,
        error: null,
      },
      email: {
        value: perjudicado.email.value,
        error: null,
      },
    };
  }

  deletePerj(i) {
    this.formulario.perjudicados.value.splice(i, 1);
    if (this.formulario.perjudicados.value.length == 0) {
      $('#perjudicadosTable').DataTable().destroy();
      this.iniTable(1);
    }
  }

  async savePerjudicado() {

    const c = await this.comprobarCamposPerjudicado();

    if (!c) {
      return;
    }

    if (this.editPerjudicado != null) {
      this.formulario.perjudicados.value.splice(this.editPerjudicado, 1);
    }

    this.editPerjudicado = null;

    this.formulario.perjudicados.value.push(this.nuevoPerjudicado);

    this.newPerjudicado = false;
    this.nuevoPerjudicado = {};
    if (this.formulario.perjudicados.value.length == 1) {
      $('#perjudicadosTable').DataTable().destroy();
      this.iniTable(1);
    }

  }

  cancelPerjudicado() {
    this.newPerjudicado = false;
    this.editPerjudicado = null;
  }

  async grabar_siniestro() {


    this.cargando = true;

    let data = {
      pol_id: undefined,
      pol_oficode: undefined,
      rie_id: undefined
    };

    const perjudicados = [];

    for (const perj of this.formulario.perjudicados.value) {
      const perjudicado = {};
      for (const c in perj) {
        perjudicado[c] = perj[c].value;
      }
      perjudicados.push(perjudicado);
    }

    for (const campo in this.formulario) {

      if (this.formulario[campo].paso != 'sin_id' && this.formulario[campo].paso != 'documentos') {
        data[campo] = this.formulario[campo].value;
      }

    }

    data['perjudicados'] = perjudicados;
    data['fecha'] = this.convertDataYmd($('#fecha').val());

    console.log('data fecha antes enviar es ', data['fecha']);


    data.pol_id = this.pol_id;
    data.pol_oficode = this.pol_oficode;
    data.rie_id = this.rie_id;


    if (this.siniestro_creado === false) {
      const result = await this.global.apiCall('siniestrosRC/grabar_siniestro', 'POST', false, data);
      this.cargando = false;
      if (!result.status) {
        this.siniestro_creado = false;
        alert(result.message);
      } else {

        this.formulario.sin_id.value = result.data;
        this.sin_id = result.data;
        this.siniestro_creado = true;
      }
    }

    if (this.siniestro_creado === true && this.sin_id > 0) {
      let tempsin_alias = await this.global.apiCall('siniestros/getSinAlias', 'POST', false, {sin_id: this.sin_id});
      this.sin_alias = tempsin_alias['sin_alias'];
      console.log('sin_alias ', this.sin_alias);

      await this.uploadDocumentos();

      this.cargando = true;


      for (const campo in this.formulario) {

        if (this.formulario[campo].paso != 'sin_id' && this.formulario[campo].paso != 'documentos') {
          continue;
        }

        data[campo] = this.formulario[campo].value;

      }

      const result = await this.global.apiCall('siniestrosRC/cargarFicheros', 'POST', false, {data, docs: this.documentos});

      this.cargando = false;

      if (!result.status) {
        this.errorSubidaDocumento = true;
        /**
         * todo: call SP que creará tarea indicando que no se ha podido subir documentación de siniestro.
         */


      }
      console.log('errorSubidaDocumento ', this.errorSubidaDocumento);
      console.log('THIS varaibles , ', this);
      if (this.formulario.tipoSiniestro.value === 'Responsabilidad Civil') {
        this.paso = 6;
      } else {
        this.paso = 5;
      }
    }


  }

  checkLast() {
    if (this.paso == 5) {
      if (this.formulario.tipoSiniestro.value == 'Responsabilidad Civil') {
        return true;
      } else {
        return false;
      }
    }
    if (this.paso == 6) {
      return false;
    }
    return true;
  }


  async doAsyncTask() {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {

        this.lookAndFeel();
        this.showInit = true;
        resolve();
      }, 1000);
    });
  }


  async lookAndFeel() {

    this.entidad = this.location.path();

    const logodefault = 'https://media.ersm.net/logos/ersm.png';

    this.logo = '';
    this.cargando = true;
    if (this.entidad) {
      this.entidad = this.entidad.replace('/', '');
      const look_and_feel = await this.global.apiCall('lookAndFeel/getStyles', 'POST', false, {entidad: this.entidad});
      if (look_and_feel.status == true) {
        let logoimg = look_and_feel.data.logo;
        if (logoimg === undefined) {
          logoimg = look_and_feel.data.Logo;
        }
        this.logo = 'https://media.ersm.net/siniestros/logos/' + logoimg;

        let color = look_and_feel.data.color;
        if (color === undefined) {
          color = look_and_feel.data.Color;
        }
        document.documentElement.style.setProperty('--main-color', color);
        this.backgroundColor = color;
      } else {
        this.logo = logodefault;
      }
    } else {
      this.logo = logodefault;
    }
    this.cargando = false;
  }

  setStylesButton() {

    if (this.entidad) {
      const styles = {
        background: this.backgroundColor,
        'border-color': this.backgroundColor,
        color: '#fff'
      };
      return styles;
    } else {
      return false;
    }
  }

  setStylesTopBar() {
    if (this.entidad) {
      const styles = {
        'border-top-color': this.backgroundColor
      };
      return styles;
    }
  }

  setStyleTopPanel() {
    if (this.entidad) {
      const styles = {
        background: this.backgroundColor
      };
      return styles;
    }
  }

  setStylesText() {
    if (this.entidad) {
      const styles = {
        color: this.backgroundColor,
      };
      return styles;
    } else {
      return false;
    }
  }


  convertDataYmd(date) {
    var newdate = date.split('/').reverse().join('/');
    return newdate;
  }


  setRiesgoSeleccionado() {
    if (this.bienSeleccionado == null) {
      alert('Debe escoger una riesgo');
      return false;
    }
    this.showModalMultiplesRiesgos = false;
    this.rie_id = this.bienSeleccionado;
    this.paso = 2;
  }


  checkValorPrevision(prevision){

    var regex  = /^\d+(?:\.\d{0,2})$/;
    if (!regex.test(prevision)){
      this.formulario.valorPrevision.error = 'Debe ser un valor númerico';
      return false;
    }
    return true;

  }


}


